let fonObserve2 = function(entries, observer) {
	for (i = 0; i < entries.length; ++i) {
		let el = entries[i].target;

		if (entries[i].isIntersecting) {
	   
			if (!el.classList.contains("f-is-loaded")) {
				el.classList.add('f-is-loaded');
				let script= document.createElement("script");
				script.src="/szablony/public/js/out-fslightbox.js";
				document.querySelector("body").append(script);
			}
		}
	}
}

if (document.querySelector('[data-fslightbox]')) {
	const iObserverf3 = new IntersectionObserver(fonObserve2);
	iObserverf3.observe(document.querySelector("[data-fslightbox]"));
}